import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { StorageMasterService } from './core/storage/storage-master.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './core/services/interceptor/auth-interceptor.service';
import { CustomTranslateService, LanguageService, LoginService, PatientService, RegisterService, StorageService, ToastService } from './core/services';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { IonicStorageModule } from '@ionic/storage';
import { Toast } from '@ionic-native/toast/ngx';
import { SharedModule } from './shared/shared.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxSpinnerModule } from "ngx-spinner";
import { SearchPageModule } from './pages/home/search/search.module';
import { File } from '@ionic-native/file/ngx';
import { WebView } from '@ionic-native/ionic-webview/ngx';
import { BinnaclePageModule } from './pages/home/binnacle/binnacle.module';
import { DoctorPageModule } from './pages/home/doctor/doctor.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';

/* // AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
} */

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    NgxSpinnerModule,
    IonicModule.forRoot({
      rippleEffect: false,
      mode: 'md',
    }),
    TranslateModule.forRoot({
      loader: { provide : TranslateLoader, useClass : CustomTranslateService }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    IonicStorageModule.forRoot
    ({
      name: environment.DATABASE_NAME,
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    SearchPageModule,
    BinnaclePageModule,
    DoctorPageModule,
/*     TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }) */
  ],
  providers: [
    AndroidPermissions,
    Toast,
    StatusBar,
    File,
    WebView,
    LoginService,
    StorageMasterService,
    LanguageService,
    StorageService,
    MenuController,
    PatientService,
    SplashScreen,
    ToastService,
    RegisterService,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
