import { Component, OnInit } from '@angular/core';
import { LoginService, NavigationService, SpinnerService } from '@app/core/services';
import { NavController, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-deletect-account-popover',
  templateUrl: './deletect-account-popover.component.html',
  styleUrls: ['./deletect-account-popover.component.scss'],
})
export class DeleteAccountPopoverComponent implements OnInit {

  constructor(
    private popoverController: PopoverController,
    private loginService: LoginService,
    private navigationService : NavigationService,
    private spinnerService: SpinnerService,
    private navCtrl: NavController,
  ) { }

  ngOnInit() {
  }

  execute(option : any) : void {
    
    switch(option) {
      case 1 : 
        this.popoverController.dismiss({code: 100});
      break;
      case 2:
        this.spinnerService.show();
        const sub = this.loginService.deleteuser().subscribe((res: any) => {
          this.loginService.clear();
          this.popoverController.dismiss();
          // this.navigationService.navigate('login');
          this.navCtrl.navigateRoot('login');
          this.spinnerService.hide();
          sub.unsubscribe();
          }, (error): any => {

            this.spinnerService.hide();
            sub.unsubscribe();
          });
      break;
      default:
        this.popoverController.dismiss({code: 100});
      break;
    }
  }

}
