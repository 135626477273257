import { Component, Input, OnInit } from '@angular/core';
import { PatientService, SpinnerService, ToastService, LanguageService } from '@app/core/services';
import { GlobalService } from '@app/core/services/global/global.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.page.html',
  styleUrls: ['./doctor.page.scss'],
})
export class DoctorPage implements OnInit {
  @Input('modalIs') modalIs: boolean;
  @Input('patient') patient: any;
  arrSelect: Array<any> = new Array();
  white_read: any;
  constructor(private modalCtrl: ModalController,
              private patientService: PatientService,
              private global: GlobalService,
              private toastService: ToastService,
              private spinnerService: SpinnerService,
              private languageService: LanguageService
              ) { }

  ngOnInit() {
    this.onShowSelect();
  }
  get whiteOrRead() {
    return this.global.whiteOrRead
  }
  close(data = null) {
    if (this.modalIs) {
      this.modalCtrl.dismiss(data)
    }
  }

  onShowSelect() {
    const sub = this.patientService.getSearch(this.patient).subscribe((resp: any) => {
      this.arrSelect = [];
      const search = resp.data;
      search.forEach(e => {
        this.arrSelect.push({
          user: e.user,
          user_id: e.user_id,
          patient_id: e.patient_id,
          status: e.status,
          select: (e.status == "0") ? false : true,
          writeRead: parseInt(e.write_read) 
        })
      });
      console.log('arrSelect', this.arrSelect)
      sub.unsubscribe();
    }, (error): any => {
      console.error(error);
      sub.unsubscribe();
    });

  }

  changeStatus (e, d, j, value) {
    this.arrSelect.map((x, i)=> { if(i == j) x.select = d});
    let data = {
      user_id: e.user_id,
      patient_id: this.patient,
      status: null,
      write_read: value
    }
    if (!d) {
      data.status = '0';
      this.spinnerService.show();
      const sub = this.patientService.change(data).subscribe((resp: any) => {
        this.toastService.success(this.languageService.getKey('user_without_permission'));
        this.onShowSelect();
        sub.unsubscribe();
        this.spinnerService.hide();
      }, (error): any => {
        sub.unsubscribe();
        this.spinnerService.hide();
      });
    }
    if (d) {
      data.status = '2';
      this.spinnerService.show();
      const sub = this.patientService.change(data).subscribe((resp: any) => {
        this.toastService.success(this.languageService.getKey('permission_granted'));
        this.onShowSelect();
        sub.unsubscribe();
        this.spinnerService.hide();
      }, (error): any => {
        sub.unsubscribe();
        this.spinnerService.hide();
      });
    }
  }
}
