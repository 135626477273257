import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpService } from '../../http/http.service';
import { StorageService } from '../../storage/storage.service';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class LoginService {
  constructor(
    private httpService : HttpService,
    private storageService : StorageService,
    private http: HttpClient,
    ) {}

  doLogin(form: any) : any {
    const params : HttpParams =  new HttpParams()
    .append("email", form.email)
    .append("password", form.password);    

    return this.httpService.postWithoutToken(`security/login`, params);
  }
  
  logout(): Observable<any> {
    return this.http.delete(`${environment.API_URL}logout`);
  }

  isAuthenticated() : any {
    return this.storageService.getItem('isLoggedIn');
  }

  tokenIsValid() : Boolean {
    return true; 
  }

  clear() {
    this.storageService.clear();
  }

  getUser() : any {
    return this.storageService.getItem('user')
  }

  getCurrentRole() : any {
    return this.storageService.getItem('role');
  }

  getRoles() : any {
    return this.storageService.getItem('roles');
  }

  setCurrentRole(role: string) {
    this.storageService.setItem('role', role);
  }

  doRecover(form : any) : any {
    var config = {
      headers: {
          'Content-Type': 'application/json'
      }
    };
  let body = form;
  let header = new HttpHeaders();
  header = header.append('Content-Type','application/json');
    return this.http.post(`${environment.API_URL}security/forgot-password`, body, config);
  }

  deleteuser(): Observable<any> {
    var config = {
      headers: {
          'Content-Type': 'application/json'
      }
    };
  let body = null;
  let header = new HttpHeaders();
  header = header.append('Content-Type','application/json');

    return this.http.post(`${environment.API_URL}delete-account`, body, config);
  
  }
}