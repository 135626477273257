import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { BinnaclePageRoutingModule } from './binnacle-routing.module';

import { BinnaclePage } from './binnacle.page';
import { SharedModule } from '@app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    BinnaclePageRoutingModule,
    SharedModule,
    BrowserModule,
  ],
  declarations: [BinnaclePage],
  entryComponents: [BinnaclePage],
  exports: [BinnaclePage],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BinnaclePageModule {}
