import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { GlobalService } from '@app/core/services/global/global.service';
import { ConfigStorage } from '@app/core/storage';
import { IonContent, IonSlides, ModalController } from '@ionic/angular';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent implements OnInit {
  @ViewChild('slides', { static: true }) slides: IonSlides;
  @ViewChild(IonContent, { static: true }) content: IonContent;
  slideOpts: any = {
    // pager: false,
    initialSlide: 0,
  };

  currentIndex: number = 0;
  @Input('isModal') isModal: boolean;
  logo: string = `${AppConfig.DEFAULT_LOGO}`;
  menu: string;
  slider: any;
  isMenu: boolean = false;
  selectImg: string = '/assets/img/guide/01.png'
  constructor(
    private config: ConfigStorage,
    private modalCtrl: ModalController,
    private global: GlobalService,
    private menuController: MenuController
  ) { }


  ngOnInit() {

  }
  ngAfterViewInit(){
    this.slideOpts = {    
      onlyExternal: true,
      pager: true,
      allowTouchMove: false,
      onInit: (slides: any) =>
        this.slider = slides
    }
  }
  // ionViewDidEnter(){
  //   this.slides.update();
  // }

  get arrMenu() {
    return this.global.auxMenu;
  }

  get arrPhoto() {
    return this.global.auxPhoto;
  }
  close() {
    this.modalCtrl.dismiss();
  }

  goToClick(e) {
    this.slides.slideTo(e, 0);
  }

  ionSlideDidChange (slides: IonSlides) {
    setTimeout(() => {
      slides.getActiveIndex().then((index: number) => {
          this.currentIndex = index;       
      }); 
  }, 1000);
  }

  nextSlide() {
    this.slides.slideNext();
  }

  prevSlide() {
    this.slides.slidePrev();
  }

  getSelectImage(data, index) {
    this.currentIndex = index;
    this.selectImg = data.img;
  }

  getSelectNomenclatura() {
    this.currentIndex = -1;
    this.selectImg = '/assets/img/guide/33.png';
  }

  openMenu() {
    // this.isMenu = !this.isMenu ? true : false;
 

    if (!this.isMenu) {
      this.isMenu = true;  
      console.log("holaa222");
      this.menuController.enable(true, 'custom');
      this.menuController.open('custom');
      console.log(this.menuController);
    } else {
      this.isMenu = false; 
      this.menuController.close();
      console.log("holaa33");
    }
    
  }
}
