import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LanguageService, PatientService, SpinnerService, ToastService } from '@app/core/services';
import { GlobalService } from '@app/core/services/global/global.service';
import { IonContent, IonSelect, IonSlides, ModalController } from '@ionic/angular';
import { DoctorPage } from '../doctor/doctor.page';
import * as _ from 'lodash';
@Component({
  selector: 'app-search',
  templateUrl: './search.page.html',
  styleUrls: ['./search.page.scss'],
})
export class SearchPage implements OnInit {
  @ViewChild('slides', { static: true }) slides: IonSlides;
  @ViewChild(IonContent, { static: true }) content: IonContent;
  // @ViewChild('white_read',{static: true}) select: ElementRef;
  @ViewChild('white_read') white_read:ElementRef;
  @Input('isModal') isModal: boolean;
  @Input('patient') patient: any;
  search: any = '';
  menu: string;
  segment: any = 0;
  showSearchbar: boolean = true;
  slideOpts: any = {
  };
  slider: any;
  registerForm: FormGroup;
  temp: number = 0;
  temparray: any[] = [];
  menuOptions: Array<any> = [
    {
      value: 'no',
      name: 'médicos sem permissão',
    },
    {
      value: 'si',
      name: 'Médicos com permissão',
    },
  ];
  arrSelect: Array<any> = new Array();
  arrUser: Array<any> = new Array();
  constructor(private modalCtrl: ModalController,
              private global: GlobalService,
              private patientService: PatientService,
              private formBuilder: FormBuilder,
              private toastService: ToastService,
              private spinnerService: SpinnerService,
              private langService: LanguageService) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      users: this.formBuilder.array([]),
      search: this.formBuilder.array([]),
    });

    this.  menuOptions = [
      {
        value: 'no',
        name: this.langService.getKey('dr_without_permits'),
      },
      {
        value: 'si',
        name: this.langService.getKey('dr_with_permits'),
      },
    ];
    
  }
  ngAfterViewInit() {
    // console.log(this.white_read.nativeElement)
    // this.select.nativeElement.value = 0;
    // setTimeout(() => {
    //   console.log(this.select.nativeElement.value);
    // }, 1000);
  }
  get whiteOrRead() {
    return this.global.whiteOrRead
  }
  close(data = null) {
    if (this.isModal) {
      this.modalCtrl.dismiss(data)
    }
  }

  // updateRegisterForm(list) {
  //   let _users = new FormGroup({});
  //   let users = new FormArray([]);
  //   list.forEach((e) => {
  //     _users = this.formBuilder.group({
  //         id: e.id,
  //         firstname: e.firstname,
  //         lastname: e.lastname,
  //         email: e.email,
  //         patient_id: e.patient_id,
  //         isSelect: null,
  //         select: false,
  //       });
  //       users.push(_users);
  //   });
  //   return users;
  // }

  share() {
    const data = [];

    this.initUsers.value.forEach(element => {
       data.push({
        user_id: element.id,
        patient_id: element.patient_id,
        select: element.select,
        write_read: element.isSelect
      });
    });
    this.spinnerService.show();
    const sub = this.patientService.saveSearch(data).subscribe((resp: any) => {
      this.toastService.success(this.langService.getKey('share_successfully'));
      // this.show();
      // this.onShowSelect();
      this.arrUser = [];
      this.initUsers.clear();
      this.spinnerService.hide();
      sub.unsubscribe();
    }, (error): any => {
      sub.unsubscribe();
      this.spinnerService.hide();
    });
  }

  // onSearch(e) {
  //   const resp = e.target.value;
  //   const sub = this.patientService.showUsers(this.patient, resp).subscribe((resp: any) => {
  //     this.arrUser = [];
  //     const search = resp.data;
  //     this.arrUser = search;
  //     sub.unsubscribe();
  //   }, (error): any => {
  //     sub.unsubscribe();
  //   });
  // }

  onShowSelectMedico(e) {
    const sub = this.patientService.showUsers(this.patient, e.value).subscribe((resp: any) => {
      this.initUsers.clear();
      this.arrUser = [];
      const search = resp;
      this.arrUser = search;
      sub.unsubscribe();
    }, (error): any => {
      sub.unsubscribe();
    });
  //   const sub = this.patientService.getSearch(this.patient).subscribe((resp: any) => {
  //     this.arrSelect = [];
  //     const search = resp.data;
  //     this.arrSelect = search;
  //     sub.unsubscribe();
  //   }, (error): any => {
  //     console.error(error);
  //     sub.unsubscribe();
  //   });

  }

  show () {
    const sub = this.patientService.showUsers(this.patient, '').subscribe((resp: any) => {
      this.arrUser = [];
      const search = resp.data
      this.arrUser = search;
      this.arrUser.forEach(e => {
        if (e.select === true) this.temparray.push(e);
      })
      sub.unsubscribe();
    }, (error): any => {
      console.error(error);
      sub.unsubscribe();
    });
  }

  get validate() {
    return this.registerForm.controls;
  }

  get initUsers() {
    return this.registerForm.get('users') as FormArray;
  }

  onChange(value, data: any, e: any) {
    let control = <FormArray>this.registerForm.controls.users;

    if (e) {
      const patient = this.patient;
      if (value !=undefined) {
        var resul = {
          id: data.id,
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          patient_id: this.patient,
          isSelect: value,
          select: e,
        }
        const patient = this.patient;
        const id = control.value.find(o => { return o.patient_id == patient });
        const is = !!id ? true : false;
        if (!is) {
          control.push(new FormControl(resul));
          this.temp = control.length;
          this.temparray.push(resul);
        }
      }
    } else {
      let index = control.controls.findIndex(x => x.value.id == data.id);
      control.removeAt(index);
      this.temp = control.length;
      let temp_index = this.temparray.findIndex(x => x.id == data.id);
      this.temparray.splice(temp_index, 1);
    }
  }

  modalIs: boolean;
  async openMedicos() {
    if (this.modalIs) {
      return;
    }
    this.modalIs = true;
    const modal = await this.modalCtrl.create({
      component: DoctorPage,
      componentProps: {
        modalIs: true,
        patient: this.patient
      }
    });
    modal.onDidDismiss().then((data: any) => {
      if (data && data.data) {
      }
      this.modalIs = false;
    });
    await modal.present();
  }
}
