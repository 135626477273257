import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConfig } from '@app/app.config';
import { AlertController, ModalController, NavController, PopoverController, } from '@ionic/angular';
import { DrawerComponent, MenuOption } from '../drawer/drawer.component';

import { ImportTreeComponent } from '../import-tree/import-tree.component';
import { OutSesionPopoverComponent } from '../out-sesion-popover/out-sesion-popover.component';
import { DeleteAccountPopoverComponent } from '../deletect-account-popover/deletect-account-popover.component';
import { myEnterAnimation } from '../animations/enter';
import { myLeaveAnimation } from '../animations/leave';
import { HelpComponent } from '../help/help.component';
import { BibliographyComponent } from '../bibliography/bibliography.component';
import { LanguageService,StorageService, PatientService, SpinnerService } from '@app/core/services';
import { ProfileComponent } from '../profile/profile.component';
@Component({
  selector: 'app-navbar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {

  @Input() btnImportTree: boolean;
  @Input() patient: any;
  @Input('outPatient') outPatient: boolean;
  @Output() goHome = new EventEmitter<any>();
  isModal: boolean;
  isMenu: boolean;
  logo: string = `${AppConfig.DEFAULT_LOGO}`;
  isMail: boolean;
  node: any;
  constructor(
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private storageService: StorageService,
    private popoverController: PopoverController,
    private patientService: PatientService,
    private alertController: AlertController,
    private spinnerService: SpinnerService,
    private languageService: LanguageService
  ) { }

  ngOnInit() {
    this.patientService.selectedNode.subscribe((node) => this.node = node);
  }
  isAlert: boolean;
  async onHome() {
    if (!!this.outPatient) {
      if (this.isAlert) {
        return;
      }
      this.isAlert = true;
      const alert = await this.alertController.create({
        cssClass: 'modal-popover',
        header: this.languageService.getKey('alert.title'),
        subHeader: this.languageService.getKey('lose_the_changes'),
        buttons: [{
          text: this.languageService.getKey('cancel'),
          role: 'cancel',
          handler: async () => {
          }
        },{
          text: this.languageService.getKey('accept'),
          role: 'accept',
          handler: async () => {
            this.isAlert = false;
            this.navCtrl.navigateBack('home').then(() => {
              this.goHome.emit();
            });
          }
        }]
      });
      alert.onDidDismiss().then(async (data) => {
        this.isAlert = false;
      });
      await alert.present();
    } else {
      this.navCtrl.navigateBack('home').then(() => {
        this.goHome.emit();
      });
    }
  }
  async onMaile() {
    if (this.isMail) {
      return;
    }
    this.isMail = true;
    const alert = await this.alertController.create({
      cssClass: 'modal-popover',
      header: this.languageService.getKey('warning'),
      subHeader: this.languageService.getKey('send_a_message_support'),
      buttons: [{
        text: this.languageService.getKey('cancel'),
        role: 'cancel',
        handler: async () => {
        }
      }, {
        text: this.languageService.getKey('accept'),
        role: 'acept',
        handler: async () => {
          // window.location.href = "mailto:soporte@ttreeapp.com";
          window.open(
            'mailto:soporte@ttreeapp.com',
            '_blank' // <- This is what makes it open in a new window.
          );
        }
      }]
    });
    alert.onDidDismiss().then(async (data) => {
      this.isMail = false;
    });
    await alert.present();
  }
  async bibliography() {
    if (this.isModal) {
      return;
    }
    this.isModal = true;
    const modal = await this.modalCtrl.create({
      component: BibliographyComponent,
      componentProps: {
        isModal: this.isModal
      },
    });
    modal.onDidDismiss().then(() => this.isModal = false);
    return await modal.present();
  }

  async help() {
    if (this.isModal) {
      return;
    }
    this.isModal = true;
    const modal = await this.modalCtrl.create({
      component: HelpComponent,
      cssClass: 'modal-help',
      componentProps: {
        isModal: this.isModal,
      },
    });
    modal.onDidDismiss().then(() => this.isModal = false);
    return await modal.present();
  }

  async importTree() {
    if (this.isModal || !this.patient) {
      return;
    }
    this.isModal = true;
    const modal = await this.modalCtrl.create({
      component: ImportTreeComponent,
      componentProps: {
        patient: this.patient,
        isModal: this.isModal
      },
      cssClass: 'import-tree'
    });
    modal.onDidDismiss().then(() => this.isModal = false);
    return await modal.present();
  }

  async logout() {
    const popover = await this.popoverController.create({
      component: OutSesionPopoverComponent,
      cssClass: 'outSesionPopover',
      // translucent: true
    });
    return await popover.present();
  }
  async deleteaccount() {
    const popover = await this.popoverController.create({
      component: DeleteAccountPopoverComponent,
      cssClass: 'outSesionPopover',
      // translucent: true
    });
    return await popover.present();
  }
  async profile() {
    if (this.isModal) {
      return;
    }
    this.isModal = true;
    const modal = await this.modalCtrl.create({
      component: ProfileComponent,
      cssClass: 'modal-help',
      componentProps: {
        patient: this.patient,
        isModal: this.isModal
      },
    });
    modal.onDidDismiss().then(() => this.isModal = false);
    return await modal.present();
  }
  async menu () {
    if (this.isMenu) {
      return;
    }
    const options: Array<MenuOption> = Array<MenuOption>();
    if (!!this.patient) {
      options.push(new MenuOption({
        id: 1,
        title: `${this.languageService.getKey('patient')}: ${this.patient.firstname} ${this.patient.lastname}`,
        btnEndIcon: 'person-outline'
      }));
    
    }
    options.push(new MenuOption({
      id: 7,
      title: this.languageService.getKey('form'),
      btnEndIcon: 'external-link-alt-solid'
    }));

    options.push(new MenuOption({
      id: 2,
      title: this.languageService.getKey('bibliography'),
      btnEndIcon: 'book-outline'
    }));

    options.push(new MenuOption({
      id: 3,
      title: this.languageService.getKey('help'),
      btnEndIcon: 'help-outline'
    }));

    options.push(new MenuOption({
      id: 5,
      title: this.languageService.getKey('contact_mail'),
      btnEndIcon: 'settings-outline',
      mail: 'mailto:soporte@ttreeapp.com'
    }));
    options.push(new MenuOption({
      id: 8,
      title: this.languageService.getKey('profile'),
      btnEndIcon: 'person',
    }));
    options.push(new MenuOption({
      id: 6,
      title: this.languageService.getKey('leave'),
      btnEndIcon: 'log-out-outline'
    }));

    options.push(new MenuOption({
      id: 9,
      title: this.languageService.getKey('deleteaccount'),
      btnEndIcon: 'log-out-outline'
    }));

    
    this.isMenu = true;
    const modal = await this.modalCtrl.create({
      component: DrawerComponent,
      componentProps: {
        options 
      },
      enterAnimation: myEnterAnimation,
      leaveAnimation: myLeaveAnimation,
      cssClass: 'modal-in'
      
    });
    modal.onDidDismiss().then((resp: any) => {
      this.isMenu = false;
      if (resp && resp.data && resp.data.option) {
        switch (resp.data.option) {
          case 1:
            this.openTree(this.isMenu);
          break;
          case 2:
              this.bibliography();
            break;
          
          case 3:
              this.help();
            break;
    
          case 4:
              this.importTree();
            break;
          
          case 5:
            this.onMaile();
            break;
    
          case 6:            
              this.logout();
            break; 
    
          case 7:            
              this.backToHighPatient();
            break; 
    
            case 8:            
            this.profile();
            break; 
            case 9:            
            this.deleteaccount();
            break; 
    
          default:
            break;
        }
      }
    });
    return await modal.present();
  }
  async openTree(e){

    this.navCtrl.navigateRoot(`home/tree/${this.patient.patient_id}`, {
      replaceUrl: true
    }).then(() => {
      this.patientService.refresh.next(true);
    });
  }
  async backToHighPatient() {
    this.spinnerService.show();
    var patient_id = await this.storageService.getItem('patient');
    var key2 = this.patient.id;
    if(this.node!==undefined){
      key2 = this.node.id
    }

    this.navCtrl.navigateRoot(`home/patient/${patient_id}`, {
      replaceUrl: true,
      queryParams: {
        // key: this.node.key
        key: key2|| 1
      }
    }).then(() => {
      this.spinnerService.hide();
    });
  }
}
