import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { PatientService, SpinnerService, LanguageService } from '@app/core/services';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-binnacle',
  templateUrl: './binnacle.page.html',
  styleUrls: ['./binnacle.page.scss'],
})
export class BinnaclePage implements OnInit {

  @Input('isModal') isModal: boolean;
  @Input('patient') patient: any;
  arrHistory: Array<any> = new Array();

  constructor(
    private modalCtrl: ModalController,
    private domSanitizer: DomSanitizer,
    private patientService: PatientService,
    private spinnerService: SpinnerService,
    private languageService: LanguageService) { }

  ngOnInit() {
    this.show();
  }

  innerHTML(ind) {
    return this.domSanitizer.bypassSecurityTrustHtml(`${this.languageService.getKey('dr')} ${ind.user.firstname} ${ind.user.lastname},
    ${ind.properties.log} ${this.languageService.getKey('the_day')} ${ind.updated_at}`);
  }

  show() {
    this.spinnerService.show();
    const sub = this.patientService.activity(this.patient).subscribe((resp: any) => {
      this.arrHistory = [];
      const history = resp.data.map((x) => {
        x.properties = JSON.parse(x.properties);
        if (Array.isArray(x.properties) && x.properties.length > 0) {
          x.properties = {
            log: x.properties[0]
          }
        }
        return x;
      });
      this.arrHistory = history;
      this.spinnerService.hide();
      sub.unsubscribe();
    }, (error): any => {
      sub.unsubscribe();
      this.spinnerService.hide();
    });
  }

  close(data = null) {
    if (this.isModal) {
      this.modalCtrl.dismiss(data)
    }
  }

}
